// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Polling from "../../blocks/polling/src/Polling";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Mmssettings from "../../blocks/mmssettings/src/Mmssettings";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import AutomaticFormCreation from "../../blocks/automaticformcreation/src/AutomaticFormCreation";
import Analytics from "../../blocks/analytics/src/Analytics";
import CommunityForum from "../../blocks/communityforum/src/CommunityForum";
import Location from "../../blocks/location/src/Location";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import ApplePayIntegration from "../../blocks/applepayintegration/src/ApplePayIntegration";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import Videos from "../../blocks/videos/src/Videos";
import Notifications from "../../blocks/notifications/src/Notifications";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Hashtags from "../../blocks/hashtags/src/Hashtags";
import CreateComment from "../../blocks/comments/src/CreateComment";
import InventoryManagement from "../../blocks/inventorymanagement/src/InventoryManagement";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import Search from "../../blocks/search/src/Search";
import Followers from "../../blocks/followers/src/Followers";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import AutomaticCheckoutCalculation from "../../blocks/automaticcheckoutcalculation/src/AutomaticCheckoutCalculation";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import Cftrainingwork from "../../blocks/cftrainingwork/src/Cftrainingwork";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Sorting from "../../blocks/sorting/src/Sorting";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Blockedusers from "../../blocks/blockedusers/src/Blockedusers";
import AddBlockeduser from "../../blocks/blockedusers/src/AddBlockeduser";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Couponcodegenerator from "../../blocks/couponcodegenerator/src/Couponcodegenerator";
import Couponcodetable from "../../blocks/couponcodegenerator/src/Couponcodetable";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import UserStatus from "../../blocks/userstatus/src/UserStatus";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Geofence from "../../blocks/geofence/src/Geofence";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import Linkedaccounts2 from "../../blocks/linkedaccounts2/src/Linkedaccounts2";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import Share from "../../blocks/share/src/Share";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";
import Invitefriends from "../../blocks/invitefriends/src/Invitefriends";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import Likeapost2 from "../../blocks/likeapost2/src/Likeapost2";
import AllEvents from "../../blocks/events/src/AllEvents";
import CreateEvent from "../../blocks/events/src/CreateEvent";
import EventDetail from "../../blocks/events/src/EventDetail";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import Cometchatintegration from "../../blocks/cometchatintegration/src/Cometchatintegration";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";


const routeMap = {
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Polling:{
 component:Polling,
path:"/Polling"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Mmssettings:{
 component:Mmssettings,
path:"/Mmssettings"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
AutomaticFormCreation:{
 component:AutomaticFormCreation,
path:"/AutomaticFormCreation"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
CommunityForum:{
 component:CommunityForum,
path:"/CommunityForum"},
Location:{
 component:Location,
path:"/Location"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
ApplePayIntegration:{
 component:ApplePayIntegration,
path:"/ApplePayIntegration"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
Videos:{
 component:Videos,
path:"/Videos"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
Hashtags:{
 component:Hashtags,
path:"/Hashtags"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
InventoryManagement:{
 component:InventoryManagement,
path:"/InventoryManagement"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
Search:{
 component:Search,
path:"/Search"},
Followers:{
 component:Followers,
path:"/Followers"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
AutomaticCheckoutCalculation:{
 component:AutomaticCheckoutCalculation,
path:"/AutomaticCheckoutCalculation"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
Cftrainingwork:{
 component:Cftrainingwork,
path:"/Cftrainingwork"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Blockedusers:{
 component:Blockedusers,
path:"/Blockedusers"},
AddBlockeduser:{
 component:AddBlockeduser,
path:"/AddBlockeduser"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Couponcodegenerator:{
 component:Couponcodegenerator,
path:"/Couponcodegenerator"},
Couponcodetable:{
 component:Couponcodetable,
path:"/Couponcodetable"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
UserStatus:{
 component:UserStatus,
path:"/UserStatus"},
Chat:{
 component:Chat,
path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Geofence:{
 component:Geofence,
path:"/Geofence"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
Linkedaccounts2:{
 component:Linkedaccounts2,
path:"/Linkedaccounts2"},
Servicespecificsettingsadmin2:{
 component:Servicespecificsettingsadmin2,
path:"/Servicespecificsettingsadmin2"},
Share:{
 component:Share,
path:"/Share"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
ReviewApprovalAdmin:{
 component:ReviewApprovalAdmin,
path:"/ReviewApprovalAdmin"},
ReviewApprovalBasicUser:{
 component:ReviewApprovalBasicUser,
path:"/ReviewApprovalBasicUser"},
Invitefriends:{
 component:Invitefriends,
path:"/Invitefriends"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
Likeapost2:{
 component:Likeapost2,
path:"/Likeapost2"},
AllEvents:{
 component:AllEvents,
path:"/AllEvents"},
CreateEvent:{
 component:CreateEvent,
path:"/CreateEvent"},
EventDetail:{
 component:EventDetail,
path:"/EventDetail"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
Cometchatintegration:{
 component:Cometchatintegration,
path:"/Cometchatintegration"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;